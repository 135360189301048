function hexToRgb(hex) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse shorthand hex values (e.g., #RGB)
  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }

  // Parse the hex values
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
}

function getLuminance(color) {
  const rgb = hexToRgb(color).map(x => x / 255);
  const [r, g, b] = rgb.map(c => {
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function getContrastRatio(color1, color2) {
  const luminance1 = getLuminance(color1);
  const luminance2 = getLuminance(color2);
  return (
    (Math.max(luminance1, luminance2) + 0.05) /
    (Math.min(luminance1, luminance2) + 0.05)
  );
}

function chooseBestContrastColor(baseColor, color1, color2) {
  const contrast1 = getContrastRatio(baseColor, color1);
  const contrast2 = getContrastRatio(baseColor, color2);
  return contrast1 > contrast2 ? color1 : color2;
}

export { getLuminance, getContrastRatio, chooseBestContrastColor };
